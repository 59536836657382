<template>
  <div class="app-container summary-page">
    <eHeader :query="query"/>
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="rank" label="序号"/>
      <el-table-column prop="siteName" label="社区名称"/>
      <el-table-column prop="userName" label="信号源">
        <template slot-scope="scope">
          <span class="">{{scope.row.userName}}</span>
          <div class="">{{scope.row.login}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="volume" label="交易手数"/>
      <el-table-column prop="profit" label="交易盈亏($)">
        <template slot-scope="scope">
          <span class="mount" :class="{on: +scope.row.profit < 0 }">{{scope.row.profit}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="profitVolume" label="盈利手数"/>
      <el-table-column prop="profitAmount" label="盈利金额($)"/>
      <el-table-column prop="lossVolume" label="亏损手数"/>
      <el-table-column prop="lossAmount" label="亏损金额($)">
        <template slot-scope="scope">
          <span class="mount" :class="{on: +scope.row.lossAmount < 0 }">{{scope.row.lossAmount}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import { getSum } from '../../../../api/community/tradePerformance'
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '../../../../components/community/subscription/tradePerformance/header';
  export default {
    name:'tradePerformance',
    components: {
      eHeader
    },
    mixins: [initData],
    data() {
      return {
        loading: false,
        sumInfo:{
          totalVolume: '',
          totalDirectCommission: '',
          totalCommissionAll: '',
          totalDirectInner: '',
          totalIndirectInner: '',
        }
      }
    },
    created() {
      this.$nextTick(() => {
        this.init()
      })
    },
    updated(){
      this.$nextTick(() => {
        this.setSumProfitColor();
      })
    },
    methods: {
      parseTime,
      checkPermission,
      beforeInit() {
        this.url = '/community/crm/getSignalPerformance';
        this.params = {
          page: this.page,
          size: this.size,
          userInfo: this.query.community,
        };

        const startTime = this.parseTime(this.query.startTime);
        const endTime = this.parseTime(this.query.endTime);
        if (this.query.startTime!=null && this.query.startTime!='') {
          this.params['startTime'] = startTime;
        }
        if (this.query.endTime!=null && this.query.endTime!='') {
          this.params['endTime'] = endTime;
        }
        if(this.query.siteId){
          this.params.siteId = this.query.siteId;
        }
        // 合计数据
        this.getSumInfo();
        return true;
      },
      getSumInfo(){
        let copyParams = {
          ...this.params
        };
        delete copyParams.page;
        delete copyParams.size;
        getSum(copyParams).then((res) => {
          console.log(res)
          this.sumInfo = res;
        })
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          switch (index) {
            case 0:
              sums[index] = '合计';
              break;
            case 3:
              sums[index] = `${this.sumInfo.volume}`;
              break;
            case 4:
              sums[index] = `${this.sumInfo.profit}`;
              break;
            case 5:
              sums[index] = `${this.sumInfo.profitVolume}`;
              break;
            case 6:
              sums[index] = `${this.sumInfo.profitAmount}`;
              break;
            case 7:
              sums[index] = `${this.sumInfo.lossVolume}`;
              break;
            case 8:
              sums[index] = `${this.sumInfo.lossAmount}`;
              break;
          }
        });

        return sums;
      },
      setSumProfitColor(){
        const s_table = document.getElementsByClassName('el-table__footer-wrapper')[0]
        const child_tr_childivs = s_table.getElementsByTagName('tr')[0].childNodes;
        child_tr_childivs.forEach((item,index) => {
          if(index == 4 || index == 8){
            let divEle = item.getElementsByClassName('cell')[0];
            if(divEle.innerText.indexOf('-') > -1){
              divEle.setAttribute('style', 'color: #E74B3A');
            }else {
              divEle.setAttribute('style', 'color: #11AC80');
            }
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
.mount {
  &.on {
    color: red;
   }
}
</style>
