<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
      v-model="query.siteId"
      filterable
      placeholder="请选择"
      style="width: 200px;"
      class="filter-item"
      clearable
    >
      <el-option
        v-for="item in sites"
        :key="item.id"
        :label="item.siteName"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-input
      v-model="query.community"
      clearable
      placeholder="信号源呢称/MT4"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <span>平仓时间</span>
    <el-date-picker
      class="filter-item"
      v-model="query.startTime"
      type="date"
      placeholder="选择开始日期">
    </el-date-picker>
    <span>至</span>
    <el-date-picker
      class="filter-item"
      v-model="query.endTime"
      type="date"
      placeholder="选择结束日期">
    </el-date-picker>
    <el-button v-if="checkPermission(['ADMIN','TRADEORDER_ALL','TRADEORDER_SELECT'])" class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import { getSitesData } from "@/api/cms/srSite";
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      time:'1',
      times: [
        { key: '1', display_name: '近一小时' },
        { key: '2', display_name: '近一天' },
        { key: '3', display_name: '近一周'},
        { key: '4', display_name: '近一个月' }
      ],
      sites: [],
    }
  },
  created() {
    this.getSites();
  },
  methods: {
    checkPermission,
    getSites() {
      const params = {page:0,size:1000}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    toQuery() {
      this.query.time=this.time
      this.$parent.page = 0
      this.$parent.init()
    },
  }
}
</script>
